import { ARBITRUM, ARBITRUM_TESTNET, AVALANCHE, MAINNET } from "./chains";
import { isLocal } from "./env";

export const GMX_STATS_API_URL = "https://stats.gmx.io/api";

const BACKEND_URLS = {
  default: "https://gmx-server-mainnet.uw.r.appspot.com",

  [MAINNET]: "https://gambit-server-staging.uc.r.appspot.com",
  [ARBITRUM_TESTNET]: "https://gambit-server-devnet.uc.r.appspot.com",
  [ARBITRUM]: "https://gmx-server-mainnet.uw.r.appspot.com",
  [AVALANCHE]: "https://gmx-avax-server.uc.r.appspot.com",
};

export function getServerBaseUrl(chainId: number) {
  if (!chainId) {
    throw new Error("chainId is not provided");
  }

  if (document.location.hostname.includes("deploy-preview")) {
    const fromLocalStorage = localStorage.getItem("SERVER_BASE_URL");
    if (fromLocalStorage) {
      return fromLocalStorage;
    }
  }

  return BACKEND_URLS[chainId] || BACKEND_URLS.default;
}

export function getServerUrl(chainId: number, path: string) {
  return `${getServerBaseUrl(chainId)}${path}`;
}

export function getApiUrl(path: string) {
  if (isLocal()) {
    return `http://localhost:3005/${path}`;
    // return `https://positions-api.0x27.co.uk/${path}`;
  }
  return `https://positions-api.0x27.co.uk/${path}`;
}

export function getTxorApiUrl(path: string) {
  if (isLocal()) {
    return `http://localhost:3007/${path}`;
  }
  return `https://txor-api.0x27.co.uk/${path}`;
}
