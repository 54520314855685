import { useEffect, useRef, useState } from "react";
import { Trans, t } from "@lingui/macro";
import { useWeb3React } from "@web3-react/core";
import { setTraderReferralCodeByUser, validateReferralCodeExists } from "domain/referrals";
import { REFERRAL_CODE_REGEX } from "../Referrals/referralsHelper";
import { useDebounce } from "lib/useDebounce";
import { shortenAddress, useENS } from "lib/legacy";
import { createBreakpoint } from "react-use";

import { SiweMessage } from "siwe";
import decode from "jwt-decode";
import { getApiUrl } from "config/backend";
const domain = window.location.host;
const origin = window.location.origin;

function createSiweMessage(address, nonce, chainId, statement) {
  const message = new SiweMessage({
    domain,
    address,
    statement,
    uri: origin,
    version: "1",
    chainId,
    nonce,
  });
  return message.prepareMessage();
}

function LoginCard({ connectWallet, getUserJWT, setUserJWT }) {
  const { account, library, chainId } = useWeb3React();
  const signInWithEthereum = () => {
    let headers = {};
    let message;
    if (getUserJWT) {
      headers.Authorization = "Bearer " + getUserJWT;
    }
    fetch(`${getApiUrl("nonce")}`, { headers })
      .then((result) => result.text())
      .then((jwt) => {
        // get the initial JWT to set up the login & request a signature
        // console.log("SET JWT", jwt)
        setUserJWT(jwt);
        headers.Authorization = "Bearer " + jwt;
        const decoded = decode(jwt);
        // console.log(decoded)
        return decoded.nonce;
      })
      .then(async (nonce) => {
        // console.log(nonce)
        message = createSiweMessage(account, nonce, chainId, "Verify to 0x27 that you own this wallet.");
        return library.getSigner().signMessage(message);
      })
      .then(async (signature) => {
        // console.log(signature);
        // verify the signature and return a JWT
        return fetch(`${getApiUrl("verify")}`, {
          headers: { ...headers, "Content-Type": "application/json" },
          method: "POST",
          body: JSON.stringify({ message, signature }),
        });
      })
      .then((result) => result.text())
      .then((jwt) => {
        // store the authenticated JWT in localstorage
        // console.log(jwt)
        setUserJWT(jwt);
        // console.log("LoginCard: ", getUserJWT)
      });
    // console.log(await signer.signMessage(message));
  };
  const useBreakpoint = createBreakpoint({ L: 600, M: 550, S: 400 });
  const breakpoint = useBreakpoint();
  const { ensName } = useENS(account);

  return (
    <div className="referral-card section-center mt-medium">
      <h2 className="title">
        <Trans>{account ? "Authenticate Your Wallet" : "Connect Your Wallet"}</Trans>
      </h2>
      <p className="sub-title">
        <Trans>Please connect & authenticate the wallet which is used to manage positions.</Trans>
      </p>
      <div className="card-action">
        {
          <button
            className="App-cta Exchange-swap-button"
            style={{ marginBottom: "10px" }}
            disabled={account}
            type="submit"
            onClick={connectWallet}
          >
            <Trans>
              {account
                ? "Logged into " + (ensName || shortenAddress(account, breakpoint === "S" ? 9 : 13))
                : "Connect Wallet"}
            </Trans>
          </button>
        }
        {
          <button
            className="App-cta Exchange-swap-button"
            type="submit"
            disabled={!account}
            onClick={signInWithEthereum}
          >
            <Trans>Authenticate Wallet</Trans>
          </button>
        }
      </div>
    </div>
  );
}
export default LoginCard;
