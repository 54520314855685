import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useWeb3React } from "@web3-react/core";
import { Trans, t } from "@lingui/macro";
import useSWR from "swr";
import { PieChart, Pie, Cell, Tooltip } from "recharts";
import TooltipComponent from "components/Tooltip/Tooltip";
import { fetchConvexData } from "../../components/Positions/Convex";
// import { MulticallRequestConfig } from "lib/multicall";
import { executeMulticall } from "lib/multicall/utils";

import hexToRgba from "hex-to-rgba";
import { BigNumber, Contract, ethers } from "ethers";

import maNFTChronos from "abis/maNFTChronos.json";
import GnosisSafeL2 from "abis/GnosisSafeL2.json";
import { helperToast } from "lib/helperToast";
import { useCopyToClipboard } from "react-use";

import {
  USD_DECIMALS,
  GMX_DECIMALS,
  GLP_DECIMALS,
  BASIS_POINTS_DIVISOR,
  DEFAULT_MAX_USDG_AMOUNT,
  getPageTitle,
  importImage,
  arrayURLFetcher,
  USDC_DECIMALS,
  USDT_DECIMALS,
  DAI_DECIMALS,
  FRAX_DECIMALS
} from "lib/legacy";
import { useTotalGmxInLiquidity, useGmxPrice, useTotalGmxStaked, useTotalGmxSupply } from "domain/legacy";
import useFeesSummary from "domain/useFeesSummary";

import { getContract } from "config/contracts";

import VaultV2 from "abis/VaultV2.json";
import ReaderV2 from "abis/ReaderV2.json";
import GlpManager from "abis/GlpManager.json";
import CurveExchangeRouter from "abis/CurveExchangeRouter.json";
import _3PoolBasePair from "abis/3PoolBasePair.json";
import Footer from "components/Footer/Footer";

import "./DashboardV2.css";

import AssetDropdown from "./AssetDropdown";
import ExternalLink from "components/ExternalLink/ExternalLink";
import SEO from "components/Common/SEO";
import useTotalVolume from "domain/useTotalVolume";
import StatsTooltip from "components/StatsTooltip/StatsTooltip";
import StatsTooltipRow from "components/StatsTooltip/StatsTooltipRow";
import { ARBITRUM, AVALANCHE, getChainName } from "config/chains";
import { getServerUrl } from "config/backend";
import { contractFetcher } from "lib/contracts";
import { useInfoTokens } from "domain/tokens";
import { getTokenBySymbol, getWhitelistedTokens, GLP_POOL_COLORS } from "config/tokens";
import { bigNumberify, expandDecimals, formatAmount, formatKeyAmount, numberWithCommas, limitDecimals, padDecimals } from "lib/numbers";
import { useChainId } from "lib/chains";
import { formatDate } from "lib/dates";
import { getIcons } from "config/icons";
import OpenPosition from "components/OpenPosition/OpenPosition";
import UpdatePosition from "components/UpdatePosition/UpdatePosition";
import ShutdownPosition from "components/ShutdownPosition/ShutdownPosition";
import { getProvider } from "lib/rpc";

const { AddressZero } = ethers.constants;

function protocolSelector(project) {
  if (project === "convex-finance") {
    return "Convex"
  }
  if (project === "chronos") {
    return "Chronos"
  }
  return "nothing"
}

export default function DashboardV2({ getUserJWT, setUserJWT, setPositions, positions }) {
  const [, copyToClipboard] = useCopyToClipboard();
  const { active, library } = useWeb3React();
  const mainnet = getProvider(undefined, 1);

  const { chainId } = useChainId();

  const chainName = getChainName(chainId);
  const currentIcons = getIcons(chainId);

  useEffect(() => {
    if (library && positions && positions.safe_address) {
      const gnosisSafeContract = new ethers.Contract(
        positions.safe_address,
        GnosisSafeL2.abi, // Replace with the actual Gnosis Safe contract ABI
        library.getSigner()
      );

      // Example event filtering for "ExecutionSuccess" event
      const filter = gnosisSafeContract.filters.ExecutionFromModuleSuccess();

      const listener = async (module, event) => {
        console.log('New Gnosis Safe transaction:', event.transactionHash);
        console.log('Module:', module);
        // Handle the Gnosis Safe transaction event
        copyToClipboard(event.transactionHash)
        helperToast.success(t`Tx Hash: ${event.transactionHash} (Copied)`, { autoClose: 30000 });
      };

      gnosisSafeContract.on(filter, listener);
      return () => {
        gnosisSafeContract.off(filter, listener);
      };
    }
  }, [library, copyToClipboard, positions]);

  const { data: convex } = useSWR([`Dashboard:convexData:${active}`, positions?.safe_address],
  // const { data: convex } = useSWR([`Dashboard:convexData:${active}`, "0x682ba005af4276749a26dc18400b395dac408191"],
    {
      fetcher: (key, address) => {
        return fetchConvexData(address || ethers.constants.AddressZero)
        .then((data) => data);
      },
    }
  );
  const { data: gasPrice } = useSWR([`Dashboard:gasPrice:${active}`, positions?.txor_address],
    {
      fetcher: (key, address) => {
        let promise = !address ? Promise.resolve("") : mainnet.getGasPrice();
        return promise
        .then((data) => data);
      },
      refreshInterval: 10000
    }
  );
  // console.log(gasPrice?.toString())
  const { data: txorBalance } = useSWR([`Dashboard:txorBalance:${active}`, positions?.txor_address],
    {
      fetcher: (key, address) => {
        let promise = !address ? Promise.resolve("") : library.getBalance(address);
        return promise
        .then((data) => {
          return data
        });
      },
      refreshInterval: 10000
    }
  );
  // console.log(txorBalance?.toString())
  // console.log(convex)

  const pools = {
    "5ce23e7e-3800-4c9c-ad30-6db3db0515a1": { pid: 25, lpToken: "0x06325440D014e39736583c165C2963BA99fAf14E" },
    "bd4ee30b-31df-42bb-8ae8-76a6f8a76452": { pid: 155, lpToken: "0x828b154032950C8ff7CF8085D841723Db2696056" },
    "6d342d6a-9796-443e-8152-b94e8b6021fc": { pid: 128, lpToken: "0xf43211935C781D5ca1a41d2041F397B8A7366C7A" },
    "ec2c823c-a136-4677-b581-c2ff9807602c": { pid: 49, lpToken: "0xC4C319E2D4d66CcA4464C0c2B32c9Bd23ebe784e" }, 
    "9abd7e18-71a0-4071-9700-4c5c1e99db10": { pid: 23, lpToken: "0xA3D87FffcE63B53E0d54fAa1cc983B7eB0b74A9c" }, 
    "fc7701fc-e290-4606-a8be-9e4ba6c5f91a": { pid: 154, lpToken: "0x6c38cE8984a890F5e46e6dF6117C26b3F1EcfC9C" },
    "3046ac00-53f7-4ad5-b1f8-c323501d4d3e": { pid: 127, lpToken: "0x5b6C539b224014A09B3388e51CaAA8e354c959C8" },
  }

  const { data: yields } = useSWR(["https://yields.llama.fi/pools"], {
    fetcher: (url) =>
      fetch(url)
        .then((res) => res.json())
        .then((res) => {
          return res.data.filter(d => d.chain === "Ethereum" && d.project === "convex-finance")
        })
        .then((res) => {
          let listed = res.filter(d => pools[d.pool])
          return listed.map(p => {
            return {...p, ...pools[p.pool] }
          })
        }),
    refreshInterval: 30000
  });
  // console.log(yields)

  let allPositionData = yields?.map(y => {
    convex?.poolData.forEach(c => {
      if (c.lp.address.toLowerCase() == y.lpToken.toLowerCase()){
        y.convex = c
      }
    })
    return y
  }) || [];

  const maNFTAddress = getContract(ARBITRUM, "maNFTChronos");
  const { data: nftIDs } = useSWR(
    // [`arbitrum:manft:tokens:${active}`, ARBITRUM, maNFTAddress, "tokensOfOwner", "0x3f23cdbfde67647aed4a0a8bc28dd108627026e9"],
    [`arbitrum:manft:tokens:${active}`, ARBITRUM, maNFTAddress, "tokensOfOwner", positions?.safe_address],
    {
      fetcher: contractFetcher(undefined, maNFTChronos),
    }
  );
  // get the last nft id thats created
  //@audit hacky
  let latestNFT = null;
  if (nftIDs && nftIDs?.length){
    latestNFT = nftIDs.map(id => id.toNumber()).pop()
  }
  allPositionData.push({
    "chain": "Arbitrum",
    "project": "chronos",
    "symbol": "LUSD-USDC",
    "tvlUsd": 2479290,
    "apyBase": 0.08866,
    "apyReward": 5.69736,
    "apy": 7.85602,
    "rewardTokens": [
      "0xd533a949740bb3306d119cc777fa900ba034cd52",
      "0x4e3fbd56cd56c3e72c1403e103b45db9da5b9d2b"
    ],
    "pool": "3046ac00-53f7-4ad5-b1f8-c323501d4d3e",
    "apyPct1D": -0.04655,
    "apyPct7D": 0.44033,
    "apyPct30D": 0.90399,
    "stablecoin": false,
    "ilRisk": "no",
    "exposure": "multi",
    "predictions": {
      "predictedClass": "Stable/Up",
      "predictedProbability": 65,
      "binnedConfidence": 2
    },
    "poolMeta": null,
    "mu": 6.96064,
    "sigma": 0.11275,
    "count": 110,
    "outlier": false,
    "underlyingTokens": [
      "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2",
      "0xBe9895146f7AF43049ca1c1AE358B0541Ea49704"
    ],
    "il7d": null,
    "apyBase7d": null,
    "apyMean30d": 10.80,
    "volumeUsd1d": null,
    "volumeUsd7d": null,
    "apyBaseInception": null,
    "pid": latestNFT,
    "lpToken": "0x5b6C539b224014A09B3388e51CaAA8e354c959C8",
  })
  // console.log("nftID", nftIDs);

  // map the positions data stop loss into the table of all available positions
  allPositionData = allPositionData.map(d => {
    let stop = positions?.lp_strategies?.find(s => s.pid === d.pid) || null;
    return { ...d, stop }
  })

  let activePositions = allPositionData.filter(d => {
    return d.convex?.userStaked > 0;
  })
  let totalPositionsNAV = allPositionData.reduce((acc, cv) => {
    let posVal = cv.convex?.userStaked || 0;
    return acc+posVal;
  }, 0);
  console.log("allPositionData", allPositionData)

  console.log("positions", positions);

  let aums, totalSupplies, totalTokenWeights;
  const curveRouterAddress = getContract(1, "CurveExchangeRouter");
  // console.log(curveRouterAddress)
  // const underlyingTokens = allPositionData.reduce((acc, cv) => {
  //   // const [, symbol] = cv.symbol.split("-");
  //   // acc[cv.symbol] = { symbol, address: cv.underlyingTokens[1] }
  //   acc[cv.symbol] = { address: cv.underlyingTokens[1] }
  //   return acc
  // }, {})
  // console.log(underlyingTokens)
  
  let pegCalls = {
    curve: {
      contractAddress: curveRouterAddress,
      abi: CurveExchangeRouter.abi,
      calls: {
        // alETH: { methodName: "get_best_rate(address,address,uint256)", params: [alETH, WETH, "1000000000000000000"] },
      },
    },
  };
  const ETH = "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE";
  // const WETH = "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2";
  allPositionData.forEach(d => {
    // let toToken = d.underlyingTokens[0] === WETH ? ETH : d.underlyingTokens[0];
    // pegCalls.curve.calls[d.symbol.toLowerCase()] = { methodName: "get_best_rate(address,address,uint256)", params: [d.underlyingTokens[1], ETH, "1000000000000000000"] }
    // 1 for a stableswap `exchange`,
    // 2 for stableswap `exchange_underlying`,
    // 3 for a cryptoswap `exchange`,
    // 4 for a cryptoswap `exchange_underlying`,
    // 5 for factory metapools with lending base pool `exchange_underlying`,
    
    // 6 for factory crypto-meta pools underlying exchange (`exchange` method in zap),
    //cbeth, reth cryptov2s
    let cryptoswaps = ["0xbe9895146f7af43049ca1c1ae358b0541ea49704", "0xae78736Cd615f374D3085123A210448E74Fc6393".toLowerCase()]
    
    let swapType = cryptoswaps.includes(d.underlyingTokens[1].toLowerCase()) ? "3":"1";
    // route => token, lp, eth
    if (d.convex?.lp.minter && d.underlyingTokens[0] && d.underlyingTokens[1]) {
      pegCalls.curve.calls[d.symbol.toLowerCase()] = { 
        methodName: "get_exchange_multiple_amount(address[9],uint256[3][4],uint256,address[4])", 
        params: [
          [d.underlyingTokens[0], d.convex?.lp.minter, d.underlyingTokens[1], "0x0000000000000000000000000000000000000000", "0x0000000000000000000000000000000000000000", "0x0000000000000000000000000000000000000000", "0x0000000000000000000000000000000000000000", "0x0000000000000000000000000000000000000000", "0x0000000000000000000000000000000000000000"],
          [["1", "0", swapType], ["0", "0", "0"], ["0", "0", "0"], ["0", "0", "0"]],
          "1000000000000000000",
          ["0x0000000000000000000000000000000000000000", "0x0000000000000000000000000000000000000000", "0x0000000000000000000000000000000000000000", "0x0000000000000000000000000000000000000000"]
        ] 
      }
    }
  })
  //@audit add lusd peg price
  // const usdc = "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48";
  // const lusd = "0x5f98805A4E8be255a32880FDeC7F6728C6568bA0"
  // const lusdMinter = "0xed279fdd11ca84beef15af5d39bb4d4bee23f0ca"
  if (!!Object.keys(pegCalls.curve.calls).length){
    pegCalls.curve.calls["lusd-usdc"] = {
      methodName: "get_exchange_multiple_amount(address[9],uint256[3][4],uint256,address[4])",
      params: [
        ["0x5f98805A4E8be255a32880FDeC7F6728C6568bA0",
  "0xEd279fDD11cA84bEef15AF5D39BB4d4bEE23F0cA",
  "0x6c3F90f043a72FA612cbac8115EE7e52BDe6E490",
  "0xbEbc44782C7dB0a1A60Cb6fe97d0b483032FF1C7",
  "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48", "0x0000000000000000000000000000000000000000", "0x0000000000000000000000000000000000000000", "0x0000000000000000000000000000000000000000", "0x0000000000000000000000000000000000000000"],
        [["0", "1", "1"], ["0", "1", "12"], ["0", "0", "0"], ["0", "0", "0"]],
        "100000000000000000000000",
        ["0x0000000000000000000000000000000000000000", "0x0000000000000000000000000000000000000000", "0x0000000000000000000000000000000000000000", "0x0000000000000000000000000000000000000000"]
      ]
    }
  }
  console.log(pegCalls)


  let { data: peg } = useSWR([`Dashboard:peg:${active}`, Object.keys(pegCalls.curve.calls).length],
    {
      fetcher: (key, requests) => {
        // console.log(Object.keys(pegCalls.curve.calls).length)
        return executeMulticall(1, undefined, pegCalls)
        .then((data) => {
          // console.log(data)
          return data.curve
          // console.log(data.curve.decimals.returnValues[1])
        })
        .then((res) => {
          if (res["lusd-usdc"]){
            res["lusd-usdc"].returnValues[0] = res["lusd-usdc"].returnValues[0].mul(10000000)
          }
          return res
        });
      },
      refreshInterval: 5000
    }
  );
  // console.log(peg)

  const BasePool3PoolAddr = getContract(1, "BasePool3Pool");
  const BasePoolFraxAddr = getContract(1, "BasePoolFrax");
  const basePairCalls = {
    _3pool: {
      contractAddress: BasePool3PoolAddr,
      abi: _3PoolBasePair.abi,
      calls: {
        dai: { methodName: "balances", params: [0] },
        usdc: { methodName: "balances", params: [1] },
        usdt: { methodName: "balances", params: [2] },
      },
    },
    fraxbp: {
      contractAddress: BasePoolFraxAddr,
      abi: _3PoolBasePair.abi,
      calls: {
        frax: { methodName: "balances", params: [0] },
        usdc: { methodName: "balances", params: [1] },
      },
    },
  };

  const { data: basePairs } = useSWR([`Dashboard:basePairs:${active}`],
    {
      fetcher: (key, requests) => {
        return executeMulticall(1, undefined, basePairCalls)
          .then((res) => res);
      },
      refreshInterval: 5000
    }
  );
  
  // console.log(basePairs)


  let aum;
  if (aums && aums.length > 0) {
    aum = aums[0].add(aums[1]).div(2);
  }

  let glpPrice;
  let glpSupply;
  let glpMarketCap;
  if (aum && totalSupplies && totalSupplies[3]) {
    glpSupply = totalSupplies[3];
    glpPrice =
      aum && aum.gt(0) && glpSupply.gt(0)
        ? aum.mul(expandDecimals(1, GLP_DECIMALS)).div(glpSupply)
        : expandDecimals(1, USD_DECIMALS);
    glpMarketCap = glpPrice.mul(glpSupply).div(expandDecimals(1, GLP_DECIMALS));
  }

  let _3pool = basePairs?._3pool ? Object.keys(basePairs?._3pool).reduce((acc, name, index) => {
    let amount = basePairs?._3pool[name].returnValues[0];
    let normailisedAmount = [1, 2].includes(index) ? amount.mul("1000000000000") : amount; // add 12 decimals if its usdc/usdt
    acc.total = acc.total.add(normailisedAmount)
    acc.data.push({amount: normailisedAmount});
    return acc
  }, {total: BigNumber.from(0), data: [] }) : null;

  // format 3pool data to be suitable for the pie chart etc
  if (_3pool?.data.length){
    _3pool.data = _3pool.data.map((val, index) => {
      let colors = ["#FAB62B", "#2774CA", "#53AE94"]
      let stables = ["DAI", "USDC", "USDT"]
      let percent = val.amount.mul(100).div(_3pool.total).toNumber()
      return { ...val, value: percent, color: colors[index], name: t`${stables[index]}` }
    })
    _3pool.data = _3pool.data.sort(function (a, b) {
      if (a.value < b.value) return 1;
      else return -1;
    });
  }
  // console.log(_3pool)


  let fraxbp = basePairs?.fraxbp ? Object.keys(basePairs?.fraxbp).reduce((acc, name, index) => {
    let amount = basePairs?.fraxbp[name].returnValues[0];
    let normailisedAmount = [1].includes(index) ? amount.mul("1000000000000") : amount; // add 12 decimals if its usdc/usdt
    acc.total = acc.total.add(normailisedAmount)
    acc.data.push({ amount: normailisedAmount });
    return acc
  }, { total: BigNumber.from(0), data: [] }) : null;

  // format fraxbp data to be suitable for the pie chart etc
  if (fraxbp?.data.length) {
    fraxbp.data = fraxbp.data.map((val, index) => {
      let colors = ["#000000", "#2774CA"]
      let stables = ["FRAX", "USDC"]
      let percent = val.amount.mul(100).div(fraxbp.total).toNumber()
      return { ...val, value: percent, color: colors[index], name: t`${stables[index]}` }
    })
    fraxbp.data = fraxbp.data.sort(function (a, b) {
      if (a.value < b.value) return 1;
      else return -1;
    });
  }


  let stableGlp = 0;
  let totalGlp = 0;
  let glpPool = []; 


  let stablePercentage = totalGlp > 0 ? ((stableGlp * 100) / totalGlp).toFixed(2) : "0.0";

  glpPool = glpPool.filter(function (element) {
    return element !== null;
  });

  glpPool = glpPool.sort(function (a, b) {
    if (a.value < b.value) return 1;
    else return -1;
  });

  const [gmxActiveIndex, setGMXActiveIndex] = useState(null);

  const onGMXDistributionChartEnter = (_, index) => {
    setGMXActiveIndex(index);
  };

  const onGMXDistributionChartLeave = (_, index) => {
    setGMXActiveIndex(null);
  };

  const [glpActiveIndex, setGLPActiveIndex] = useState(null);

  const onGLPPoolChartEnter = (_, index) => {
    setGLPActiveIndex(index);
  };

  const onGLPPoolChartLeave = (_, index) => {
    setGLPActiveIndex(null);
  };

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      return (
        <div className="stats-label">
          <div className="stats-label-color" style={{ backgroundColor: payload[0].color }}></div>
          {payload[0].value}% {payload[0].name}
        </div>
      );
    }

    return null;
  };

  const updatePositionClick = (pos) => {
    // console.log(pos)
    setIsConfirming(true);
    setPosition(pos);
  };
  const shutdownPositionClick = (pos) => {
    // console.log(pos)
    setIsShutting(true);
    setPosition(pos);
  };
  const openPositionClick = (pos) => {
    // console.log(pos)
    setIsOpening(true);
    setPosition(pos);
  };


  const [position, setPosition] = useState(null);
  const [isConfirming, setIsConfirming] = useState(false);
  const [isShutting, setIsShutting] = useState(false);
  const [isOpening, setIsOpening] = useState(false);

  return (
    <SEO title={getPageTitle("Positions")}>
      {/* <div className="default-container DashboardV2 page-layout"></div> */}
      <div className="">
        { isOpening && (
          <OpenPosition
            setIsOpening={setIsOpening}
            positions={positions}
            position={position}
            getUserJWT={getUserJWT}
            setPositions={setPositions}
          ></OpenPosition>
        )
        }
        { isConfirming && (
          <UpdatePosition
            setIsConfirming={setIsConfirming}
            position={position}
            getUserJWT={getUserJWT}
            setPositions={setPositions}
          ></UpdatePosition>
        )
        }
        { isShutting && (
          <ShutdownPosition
            setIsShutting={setIsShutting}
            position={position}
            getUserJWT={getUserJWT}
            setPositions={setPositions}
          ></ShutdownPosition>
        )
        }
        {/* <div className="section-title-block">
          <div className="section-title-icon" />
          <div className="section-title-content">
            <div className="Page-title">
              <Trans>
                Positions <img width="24" src={currentIcons.network} alt="Network Icon" />
              </Trans>
            </div>
            <div className="Page-description">
              <Trans>
                Define specific risk parameters used to pull liquidity to safety
                <br />
                For more information, please read the{" "}
                <ExternalLink href="https://gmxio.gitbook.io/gmx/referrals">position documentation</ExternalLink>.
              </Trans>
            </div>
          </div>
        </div> */}
        <div className="DashboardV2-content">
          <div className="DashboardV2-cards">
            <div className="App-card">
              <div className="App-card-title">
                <Trans>Gnosis Safe</Trans>
              </div>
              <div className="App-card-divider"></div>
              <div className="App-card-content">
                <div className="App-card-row">
                  <div className="label">
                    <Trans>Safe Address</Trans>
                  </div>
                  <div>
                    <TooltipComponent
                      // handle={`$${formatAmount(tvl, USD_DECIMALS, 0, true)}`}
                      handle={`${positions?.safe_address}`}
                      position="right-bottom"
                      renderContent={() => (
                        <span>{t`The gnosis safe address which is the owner of all the positions.`}</span>
                      )}
                    />
                  </div>
                </div>
                <div className="App-card-row">
                  <div className="label">
                    <Trans>Roles Contract</Trans>
                  </div>
                  <div>
                    <TooltipComponent
                      // handle={`$${formatAmount(aum, USD_DECIMALS, 0, true)}`}
                      handle={`${positions?.roles_address}`}
                      position="right-bottom"
                      renderContent={() => (
                        <Trans>
                          <p>Specific permissions are set in this contract to allow 0x27 to interact with positions.</p>
                        </Trans>
                      )}
                    />
                  </div>
                </div>
                <div className="App-card-row">
                  <div className="label">
                    <Trans>Transactor Address</Trans>
                  </div>
                  <div>
                    <TooltipComponent
                      position="right-bottom"
                      className="nowrap"
                      handle={`${positions?.txor_address}`}
                      renderContent={() => (
                        // <StatsTooltip
                        //   title={t`Volume`}
                        //   arbitrumValue={currentVolumeInfo?.[ARBITRUM].totalVolume}
                        //   avaxValue={currentVolumeInfo?.[AVALANCHE].totalVolume}
                        //   total={currentVolumeInfo?.totalVolume}
                        // />
                        <Trans>
                          <span>
                            This is 0x27's address which will interact with positions algorithmically <br /> upon
                            detection of a specific risk event below or above a specific risk parameter
                          </span>
                        </Trans>
                      )}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="App-card">
              <div className="App-card-title">
                <Trans>Stats</Trans>
              </div>
              <div className="App-card-divider"></div>
              <div className="App-card-content">
                <div className="App-card-row">
                  <div className="label">
                    <Trans>Active Positions</Trans>
                  </div>
                  <div>{activePositions?.length || 0}</div>
                  {/* <div>${formatAmount(totalPositionsNAV, 30, 0, true)}</div> */}
                </div>

                <div className="App-card-row">
                  <div className="label">
                    <Trans>Total Positions NAV</Trans>
                  </div>
                  <div>${numberWithCommas(limitDecimals(totalPositionsNAV, 2))}</div>
                  {/* <div>${formatAmount(totalPositionsNAV, 30, 0, true)}</div> */}
                </div>
                <div className="App-card-row">
                  <div className="label">
                    <Trans>Gas Balance</Trans>
                  </div>
                  <div>{txorBalance ? formatAmount(txorBalance, 18, 5)+" ETH" : "N/A"}</div>
                </div>
                <div className="App-card-row">
                  <div className="label">
                    <Trans>Current Gas</Trans>
                  </div>
                  <div>{gasPrice ? formatAmount(gasPrice, 9, 1)+" GWei" : "N/A"}</div>
                </div>
              </div>
            </div>
          </div>
          <div className="Tab-title-section">
            <div className="Page-title">
              <Trans>Curve Base Pairs</Trans> <img src={currentIcons.network} width="24" alt="Network Icon" />
            </div>
            <div className="Page-description">
              <Trans>Visualise health of Notable Curve Base Pairs.</Trans>
            </div>
          </div>
          <div className="DashboardV2-token-cards">
            <div className="stats-wrapper stats-wrapper--gmx">
              <div className="App-card">
                <div className="stats-block">
                  <div className="App-card-title">
                    <div className="App-card-title-mark">
                      <div className="App-card-title-mark-icon">
                        <img src={importImage("Curve.webp")} width="40" alt="3Pool Token Icon" />
                      </div>
                      <div className="App-card-title-mark-info">
                        <div className="App-card-title-mark-title">3Pool</div>
                        <div className="App-card-title-mark-subtitle">3Pool</div>
                      </div>
                      <div>
                        {/* <AssetDropdown assetSymbol="GMX" /> */}
                      </div>
                    </div>
                  </div>
                  <div className="App-card-divider"></div>
                  <div className="App-card-content">
                    <div className="App-card-row">
                      <div className="label">
                        <Trans>USDT</Trans>
                      </div>
                      <div>${formatAmount(_3pool?.data[0].amount, DAI_DECIMALS, 0, true)} ({limitDecimals(_3pool?.data[0].value || 0, 2)}%)</div>
                    </div>
                    <div className="App-card-row">
                      <div className="label">
                        <Trans>DAI</Trans>
                      </div>
                      <div>${formatAmount(_3pool?.data[1].amount, DAI_DECIMALS, 0, true)} ({limitDecimals(_3pool?.data[1].value || 0, 2)}%)</div>
                    </div>
                    <div className="App-card-row">
                      <div className="label">
                        <Trans>USDC</Trans>
                      </div>
                      <div>${formatAmount(_3pool?.data[2].amount, DAI_DECIMALS, 0, true)} ({limitDecimals(_3pool?.data[2].value || 0, 2)}%)</div>
                    </div>
                    <div className="App-card-divider"></div>
                    <div className="App-card-row">
                      <div className="label">
                        <Trans>Total</Trans>
                      </div>
                      <div>${formatAmount(_3pool?.total, DAI_DECIMALS, 0, true)}</div>
                    </div>
                    {/* <div className="App-card-row">
                      <div className="label">
                        <Trans>Total Staked</Trans>
                      </div>
                      <div>
                        <TooltipComponent
                          position="right-bottom"
                          className="nowrap"
                          handle={`$${formatAmount(stakedGmxSupplyUsd, USD_DECIMALS, 0, true)}`}
                          renderContent={() => (
                            <StatsTooltip
                              title={t`Staked`}
                              arbitrumValue={arbitrumStakedGmx}
                              avaxValue={avaxStakedGmx}
                              total={totalStakedGmx}
                              decimalsForConversion={GMX_DECIMALS}
                              showDollar={false}
                            />
                          )}
                        />
                      </div>
                    </div> */}
                  </div>
                </div>
                <div className="stats-piechart" onMouseLeave={onGMXDistributionChartLeave}>
                  {_3pool && _3pool.data && _3pool.data.length > 0 && (
                    <PieChart width={210} height={210}>
                      <Pie
                        data={_3pool.data}
                        cx={100}
                        cy={100}
                        innerRadius={73}
                        outerRadius={80}
                        fill="#8884d8"
                        dataKey="value"
                        startAngle={90}
                        endAngle={-270}
                        paddingAngle={2}
                        onMouseEnter={onGMXDistributionChartEnter}
                        onMouseOut={onGMXDistributionChartLeave}
                        onMouseLeave={onGMXDistributionChartLeave}
                      >
                        {_3pool.data.map((entry, index) => (
                          <Cell
                            key={`cell-${index}`}
                            fill={entry.color}
                            style={{
                              filter:
                                gmxActiveIndex === index
                                  ? `drop-shadow(0px 0px 6px ${hexToRgba(entry.color, 0.7)})`
                                  : "none",
                              cursor: "pointer",
                            }}
                            stroke={entry.color}
                            strokeWidth={gmxActiveIndex === index ? 1 : 1}
                          />
                        ))}
                      </Pie>
                      <text x={"50%"} y={"50%"} fill="white" textAnchor="middle" dominantBaseline="middle">
                        <Trans>3Pool</Trans>
                      </text>
                      <Tooltip content={<CustomTooltip />} />
                    </PieChart>
                  )}
                </div>
              </div>
              <div className="App-card">
                <div className="stats-block">
                  <div className="App-card-title">
                    <div className="App-card-title-mark">
                      <div className="App-card-title-mark-icon">
                        <img src={importImage("Curve.webp")} width="40" alt="3Pool Token Icon" />
                      </div>
                      <div className="App-card-title-mark-info">
                        <div className="App-card-title-mark-title">FRAX BP</div>
                        <div className="App-card-title-mark-subtitle">FRAX BP</div>
                      </div>
                      <div>
                        {/* <AssetDropdown assetSymbol="GLP" /> */}
                      </div>
                    </div>
                  </div>
                  <div className="App-card-divider"></div>
                  <div className="App-card-content">
                    <div className="App-card-row">
                      <div className="label">
                        <Trans>FRAX</Trans>
                      </div>
                      <div>${formatAmount(fraxbp?.data[0].amount, DAI_DECIMALS, 0, true)} ({limitDecimals(fraxbp?.data[0].value || 0, 2)}%)</div>
                    </div>
                    <div className="App-card-row">
                      <div className="label">
                        <Trans>USDC</Trans>
                      </div>
                      <div>${formatAmount(fraxbp?.data[1].amount, DAI_DECIMALS, 0, true)} ({limitDecimals(fraxbp?.data[1].value || 0, 2)}%)</div>
                    </div>
                    <div className="App-card-divider"></div>
                    <div className="App-card-row">
                      <div className="label">
                        <Trans>Total</Trans>
                      </div>
                      <div>${formatAmount(fraxbp?.total, DAI_DECIMALS, 0, true)}</div>
                    </div>
                  </div>
                </div>
                <div className="stats-piechart" onMouseOut={onGLPPoolChartLeave}>
                  {fraxbp && fraxbp.data && fraxbp.data.length > 0 && (
                    <PieChart width={210} height={210}>
                      <Pie
                        data={fraxbp.data}
                        cx={100}
                        cy={100}
                        innerRadius={73}
                        outerRadius={80}
                        fill="#8884d8"
                        dataKey="value"
                        startAngle={90}
                        endAngle={-270}
                        onMouseEnter={onGLPPoolChartEnter}
                        onMouseOut={onGLPPoolChartLeave}
                        onMouseLeave={onGLPPoolChartLeave}
                        paddingAngle={2}
                      >
                        {fraxbp.data.map((entry, index) => (
                          <Cell
                            key={`cell-${index}`}
                            fill={entry.color}
                            style={{
                              filter:
                                glpActiveIndex === index
                                  ? `drop-shadow(0px 0px 6px ${hexToRgba(entry.color, 0.7)})`
                                  : "none",
                              cursor: "pointer",
                            }}
                            stroke={entry.color}
                            strokeWidth={glpActiveIndex === index ? 1 : 1}
                          />
                        ))}
                      </Pie>
                      <text x={"50%"} y={"50%"} fill="white" textAnchor="middle" dominantBaseline="middle">
                        FRAXBP
                      </text>
                      <Tooltip content={<CustomTooltip />} />
                    </PieChart>
                  )}
                </div>
              </div>
            </div>

            <div className="Tab-title-section">
              <div className="Page-title">
                <Trans>Strategies</Trans> <img src={currentIcons.network} width="24" alt="Network Icon" />
              </div>
              <div className="Page-description">
                <Trans>Active strategies deployed in the 0x27 ecosystem.</Trans>
              </div>
            </div>

            <div className="token-table-wrapper App-card">
              <div className="App-card-title">
                <Trans>Active Strategies</Trans> <img src={currentIcons.network} width="16" alt="Network Icon" />
              </div>
              <div className="App-card-divider"></div>
              <table className="token-table">
                <thead>
                  <tr>
                    <th>
                      <Trans>ASSET</Trans>
                    </th>
                    <th>
                      <Trans>PROTOCOL</Trans>
                    </th>
                    <th>
                      <Trans>APR</Trans>
                    </th>
                    <th>
                      <Trans>TVL</Trans>
                    </th>
                    <th>
                      <Trans>NAV</Trans>
                    </th>
                    <th>
                      <Trans>Peg Price</Trans>
                    </th>
                    <th>
                      <Trans>Stop Loss</Trans>
                    </th>
                    <th>
                      {/* <Trans>/</Trans> */}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {/* {visibleTokens.map((token) => {
                    const tokenInfo = infoTokens[token.address];
                    let utilization = bigNumberify(0);
                    if (tokenInfo && tokenInfo.reservedAmount && tokenInfo.poolAmount && tokenInfo.poolAmount.gt(0)) {
                      utilization = tokenInfo.reservedAmount.mul(BASIS_POINTS_DIVISOR).div(tokenInfo.poolAmount);
                    }
                    let maxUsdgAmount = DEFAULT_MAX_USDG_AMOUNT;
                    if (tokenInfo.maxUsdgAmount && tokenInfo.maxUsdgAmount.gt(0)) {
                      maxUsdgAmount = tokenInfo.maxUsdgAmount;
                    }
                    const tokenImage = importImage("ic_" + token.symbol.toLowerCase() + "_40.svg");

                    return (
                      <tr key={token.symbol}>
                        <td>
                          <div className="token-symbol-wrapper">
                            <div className="App-card-title-info">
                              <div className="App-card-title-info-icon">
                                <img src={tokenImage} alt={token.symbol} width="40" />
                              </div>
                              <div className="App-card-title-info-text">
                                <div className="App-card-info-title">{token.name}</div>
                                <div className="App-card-info-subtitle">{token.symbol}</div>
                              </div>
                              <div>
                                <AssetDropdown assetSymbol={token.symbol} assetInfo={token} />
                              </div>
                            </div>
                          </div>
                        </td>
                        <td>${formatKeyAmount(tokenInfo, "minPrice", USD_DECIMALS, 2, true)}</td>
                        <td>
                          <TooltipComponent
                            handle={`$${formatKeyAmount(tokenInfo, "managedUsd", USD_DECIMALS, 0, true)}`}
                            position="right-bottom"
                            className="nowrap"
                            renderContent={() => {
                              return (
                                <>
                                  <StatsTooltipRow
                                    label={t`Pool Amount`}
                                    value={`${formatKeyAmount(tokenInfo, "managedAmount", token.decimals, 0, true)} ${
                                      token.symbol
                                    }`}
                                    showDollar={false}
                                  />
                                  <StatsTooltipRow
                                    label={t`Target Min Amount`}
                                    value={`${formatKeyAmount(tokenInfo, "bufferAmount", token.decimals, 0, true)} ${
                                      token.symbol
                                    }`}
                                    showDollar={false}
                                  />
                                  <StatsTooltipRow
                                    label={t`Max ${tokenInfo.symbol} Capacity`}
                                    value={formatAmount(maxUsdgAmount, 18, 0, true)}
                                    showDollar={true}
                                  />
                                </>
                              );
                            }}
                          />
                        </td>
                        <td>{getWeightText(tokenInfo)}</td>
                        <td>{formatAmount(utilization, 2, 2, false)}%</td>
                      </tr>
                    );
                  })} */}
                  {allPositionData?.map((token) => {
                    // const tokenInfo = infoTokens[token.address];
                    // let utilization = bigNumberify(0);
                    // if (tokenInfo && tokenInfo.reservedAmount && tokenInfo.poolAmount && tokenInfo.poolAmount.gt(0)) {
                    //   utilization = tokenInfo.reservedAmount.mul(BASIS_POINTS_DIVISOR).div(tokenInfo.poolAmount);
                    // }
                    // let maxUsdgAmount = DEFAULT_MAX_USDG_AMOUNT;
                    // if (tokenInfo.maxUsdgAmount && tokenInfo.maxUsdgAmount.gt(0)) {
                    //   maxUsdgAmount = tokenInfo.maxUsdgAmount;
                    // }
                    const images = {
                      "eth-cbeth": "coinbase-wrapped-staked-eth.png",
                      // "eth-steth": "lido.png",
                      "eth-steth": "steth_logo.webp",
                      "weth-steth": "steth_logo.webp",
                      "eth-frxeth": "frxETH_icon.webp",
                      "eth-reth": "reth.webp",
                      "eth-aleth": "aleth.webp",
                      "eth-seth": "sETH.webp",
                      "lusd-usdc": "lusd.webp",
                    }
                    const tokenImage = importImage(images[token.symbol.toLowerCase()] || "");
                    return (
                      <tr key={token.symbol}>
                        <td>
                          <div className="token-symbol-wrapper">
                            <div className="App-card-title-info">
                              <div className="App-card-title-info-icon">
                                <img src={tokenImage} alt={token.symbol} width="40" />
                              </div>
                              <div className="App-card-title-info-text">
                                <div className="App-card-info-title">{token.symbol}</div>
                                {/* <div className="App-card-info-subtitle">{token.convex?.lp.name}</div> */}
                              </div>
                              <div>
                                {/* <AssetDropdown assetSymbol={token.symbol} assetInfo={token} /> */}
                              </div>
                            </div>
                          </div>
                        </td>
                        <td>{protocolSelector(token.project)}</td>
                        {/* <td>{formatKeyAmount(token.apy, "apy", 0, 2, false)}%</td> */}
                        <td>{limitDecimals(token.apy, 2)}%</td>
                        <td>${numberWithCommas(limitDecimals(token.tvlUsd, 2))}</td>
                        <td>${token.convex?.userStaked ? numberWithCommas(limitDecimals(token.convex?.userStaked, 2)) : 0}</td>
                        <td>{peg && token.symbol && peg[token.symbol?.toLowerCase()] ? 
                            // padDecimals(peg[token.symbol.toLowerCase()]?.returnValues[1], 4)
                            formatAmount(peg[token.symbol?.toLowerCase()]?.returnValues[0], 18, 4)
                            : "N/A"
                        }</td>
                        <td>{token.stop && token.stop.pid ? padDecimals(token.stop.stoploss, 4) : "N/A"}</td>
                        {token.project === "chronos" ?
                        <td>
                          {
                              token.pid ?
                                <button className="App-button-option App-card-option" onClick={() => updatePositionClick(token)}>
                                  <Trans>Update</Trans>
                                </button> : ""
                          }
                          {
                              token.pid ?
                                <button className="App-button-option App-card-option" onClick={() => shutdownPositionClick(token)}>
                                  <Trans>Close</Trans>
                                </button> : ""
                          }
                          {
                              !token.pid ?
                                <button className="App-button-option App-card-option" onClick={() => openPositionClick(token)}>
                                  <Trans>Open</Trans>
                                </button> : ""
                          }
                        </td>
                        :
                        <td>
                            <button className="App-button-option App-card-option" onClick={() => updatePositionClick(token)}>
                              <Trans>Update</Trans>
                            </button>
                            <button className="App-button-option App-card-option" onClick={() => shutdownPositionClick(token)}>
                              <Trans>Close</Trans>
                            </button>
                        </td>
                        }

                        {/* <td>
                          <TooltipComponent
                            handle={`$${formatKeyAmount(tokenInfo, "managedUsd", USD_DECIMALS, 0, true)}`}
                            position="right-bottom"
                            className="nowrap"
                            renderContent={() => {
                              return (
                                <>
                                  <StatsTooltipRow
                                    label={t`Pool Amount`}
                                    value={`${formatKeyAmount(tokenInfo, "managedAmount", token.decimals, 0, true)} ${
                                      token.symbol
                                    }`}
                                    showDollar={false}
                                  />
                                  <StatsTooltipRow
                                    label={t`Target Min Amount`}
                                    value={`${formatKeyAmount(tokenInfo, "bufferAmount", token.decimals, 0, true)} ${
                                      token.symbol
                                    }`}
                                    showDollar={false}
                                  />
                                  <StatsTooltipRow
                                    label={t`Max ${tokenInfo.symbol} Capacity`}
                                    value={formatAmount(maxUsdgAmount, 18, 0, true)}
                                    showDollar={true}
                                  />
                                </>
                              );
                            }}
                          />
                        </td> */}
                        {/* <td>{getWeightText(tokenInfo)}</td> */}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <div className="token-grid">
              {allPositionData?.map((token) => {
                const images = {
                  "eth-cbeth": "coinbase-wrapped-staked-eth.png",
                  // "eth-steth": "lido.png",
                  "eth-steth": "steth_logo.webp",
                  "weth-steth": "steth_logo.webp",
                  "eth-frxeth": "frxETH_icon.webp",
                  "eth-reth": "reth.webp",
                  "eth-aleth": "aleth.webp",
                  "eth-seth": "sETH.webp",
                  "lusd-usdc": "lusd.webp",
                }
                const tokenImage = importImage(images[token.symbol.toLowerCase()] || "");
                return (
                  <div className="App-card" key={token.symbol}>
                    <div className="App-card-title">
                      <div className="mobile-token-card">
                        <img src={tokenImage} alt={token.symbol} width="20px" />
                        <div className="token-symbol-text">{token.symbol}</div>
                        <div>
                          {/* <AssetDropdown assetSymbol={token.symbol} assetInfo={token} /> */}
                        </div>
                      </div>
                    </div>
                    <div className="App-card-divider"></div>
                    <div className="App-card-content">

                      <div className="App-card-row">
                        <div className="label">
                          <Trans>Protocol</Trans>
                        </div>
                        <div>{protocolSelector(token.project)}</div>
                      </div>
                      <div className="App-card-row">
                        <div className="label">
                          <Trans>APR</Trans>
                        </div>
                        <div>{limitDecimals(token.apy, 2)}%</div>
                      </div>
                      <div className="App-card-row">
                        <div className="label">
                          <Trans>TVL</Trans>
                        </div>
                        <div>${numberWithCommas(limitDecimals(token.tvlUsd, 2))}</div>
                      </div>
                      <div className="App-card-row">
                        <div className="label">
                          <Trans>NAV</Trans>
                        </div>
                        <div>${token.convex?.userStaked ? numberWithCommas(limitDecimals(token.convex?.userStaked, 2)) : 0}</div>
                      </div>
                      <div className="App-card-row">
                        <div className="label">
                          <Trans>Peg Price</Trans>
                        </div>
                        <div>{peg && token.symbol && peg[token.symbol?.toLowerCase()] ?
                          // padDecimals(peg[token.symbol.toLowerCase()]?.returnValues[1], 4)
                          formatAmount(peg[token.symbol?.toLowerCase()]?.returnValues[0], 18, 4)
                          : "N/A"
                        }</div>
                      </div>
                      <div className="App-card-row">
                        <div className="label">
                          <Trans>Stop Loss</Trans>
                        </div>
                        <div>{token.stop && token.stop.pid ? padDecimals(token.stop.stoploss, 4) : "N/A"}</div>
                      </div>
                      
                      <div className="App-card-row">
                        <div className="label">
                        </div>
                        <div>
                          <button className="App-button-option App-card-option" onClick={() => updatePositionClick(token)}>
                            <Trans>Update</Trans>
                          </button>
                          <button className="App-button-option App-card-option" onClick={() => shutdownPositionClick(token)}>
                            <Trans>Close</Trans>
                          </button>
                        </div>
                      </div>



                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </SEO>
  );
}
