import React, { useState, useEffect } from "react";
import { useLocalStorage } from "react-use";
import { Trans, t } from "@lingui/macro";
import { useWeb3React } from "@web3-react/core";
import { useParams } from "react-router-dom";
import useSWR from "swr";
import SEO from "components/Common/SEO";
import Tab from "components/Tab/Tab";
import Loader from "components/Common/Loader";
import Footer from "components/Footer/Footer";
import Dashboard from "pages/Dashboard/Dashboard";
import { getApiUrl } from "config/backend";
import { shortenAddress } from "lib/legacy";
import { createBreakpoint, useCopyToClipboard } from "react-use";
import cx from "classnames";
// import {
//   useReferralsData,
//   useCodeOwner,
//   useReferrerTier,
//   useUserReferralCode,
// } from "domain/referrals";
import LoginCard from "components/Positions/LoginCard";
import PayWall from "components/PayWall/PayWall";
import { ethers } from "ethers";
import { useChainId } from "lib/chains";
import ExternalLink from "components/ExternalLink/ExternalLink";
import { getIcon, getIcons } from "config/icons";

import {
  USD_DECIMALS,
  GMX_DECIMALS,
  GLP_DECIMALS,
  BASIS_POINTS_DIVISOR,
  DEFAULT_MAX_USDG_AMOUNT,
  getPageTitle,
  importImage,
  arrayURLFetcher,
  USDC_DECIMALS,
  USDT_DECIMALS,
  DAI_DECIMALS,
  FRAX_DECIMALS
} from "lib/legacy";

import { bigNumberify, expandDecimals, formatAmount, formatKeyAmount, numberWithCommas, limitDecimals, padDecimals } from "lib/numbers";

import { useDebounce } from "lib/useDebounce";

import { getContract } from "config/contracts";
import { getProvider } from "lib/rpc";

import decode from "jwt-decode";

// export async function getReferralCodeOwner(chainId, referralCode) {
//   const referralStorageAddress = getContract(chainId, "ReferralStorage");
//   const provider = getProvider(undefined, chainId);
//   const contract = new ethers.Contract(referralStorageAddress, ReferralStorage.abi, provider);
//   const codeOwner = await contract.codeOwners(referralCode);
//   return codeOwner;
// }

const erc20ABI = [
  // ERC20 standard functions
  'function balanceOf(address owner) view returns (uint256)',
  'function decimals() view returns (uint8)',
  'function symbol() view returns (string)',
];

// async function getERC20TokenBalances(walletAddress) {
//   try {
//     const covalentKey = 'cqt_rQFhMhjrRyKXTkqgMgdKddqJpWxh';
//     const covalentEndpoint = `https://api.covalenthq.com/v1/eth-mainnet/address/${walletAddress}/balances_v2/?key=${covalentKey}`;
//     // Make the request to Covalent API
//     const response = await fetch(covalentEndpoint);

//     // Extract the token balances from the response
//     const data = await response.json();
//     const balances = data.data.items;

//     // Return an array of token balances
//     // return balances.map(b => {
//     //   // b.normalised = toEther(toWei(b.balance, 0), b.contract_decimals)
//     //   return b
//     // });
//     return { data: balances }
//   } catch (error) {
//     console.error('Error retrieving ERC20 token balances:', error);
//   }
// }

function isValidEthereumAddress(address) {
  try {
    ethers.utils.getAddress(address);
    return true;
  } catch (error) {
    return false;
  }
}

async function getERC20TokenBalances(walletAddresses, completedCount, setCompletedCount) {
  try {
    const covalentKey = 'cqt_rQFhMhjrRyKXTkqgMgdKddqJpWxh';
    const covalentEndpoint = `https://api.covalenthq.com/v1/eth-mainnet/address/`;

    const balances = {};
    // setCompletedCount(0)

    const processNextAddress = async (walletAddress) => {
      const endpoint = `${covalentEndpoint}${walletAddress}/balances_v2/?key=${covalentKey}`;
      const response = await fetch(endpoint);
      const data = await response.json();
      let addWallet = data.data.items.map(x => {
        x.wallet = walletAddress;
        return x
      })
      balances[walletAddress] = addWallet;
    };

    // const rateLimitDelay = 250; // Delay in milliseconds between API calls
    // const maxRequestsPerSecond = 4;
    // const maxRequestsPerInterval = maxRequestsPerSecond * (1000 / rateLimitDelay);
    const rateLimitDelay = 1000; // Delay in milliseconds between API calls
    // const maxRequestsPerSecond = 4;
    const maxRequestsPerInterval = 4;

    for (let i = 0; i < walletAddresses.length; i += maxRequestsPerInterval) {
      const requests = [];
      console.log("req.length", requests.length)
      const endIndex = Math.min(i + maxRequestsPerInterval, walletAddresses.length);
      console.log("endIndex", endIndex)

      for (let j = i; j < endIndex; j++) {
        const walletAddress = walletAddresses[j];
        // setCompletedCount(completedCount + 1);
        // console.log(completedCount)
        requests.push(processNextAddress(walletAddress));
      }

      await Promise.all(requests);

      if (endIndex < walletAddresses.length) {
        await new Promise((resolve) => setTimeout(resolve, rateLimitDelay));
      }
    }
    var response = [];
    Object.keys(balances).map(wallet => {
      // response = balances[wallet].
      response.push(...balances[wallet]);
    })
    response = response.sort((a, b) => b.quote - a.quote);
    return { data: response };
  } catch (error) {
    console.error('Error retrieving ERC20 token balances:', error);
  }
}



function Portfolios({ connectWallet, getUserJWT, setUserJWT, positions, setPositions }) {
  const useBreakpoint = createBreakpoint({ L: 600, M: 550, S: 400 });
  const breakpoint = useBreakpoint();
  const { active, account: walletAccount, library } = useWeb3React();
  const { chainId } = useChainId();
  const { account: queryAccount } = useParams();
  let account;
  if (queryAccount && ethers.utils.isAddress(queryAccount)) {
    account = ethers.utils.getAddress(queryAccount);
  } else {
    account = walletAccount;
  }
  // console.log(positions)

  // const { loading } = useReferralsData(chainId, account);
  let loading = false;
  const networkIcon = getIcon(chainId, "network");
  const currentIcons = getIcons(chainId);
  const allPositionData = [];
  const [addrs, setAddrs] = useState("");
  const [isProcessing, setIsProcessing] = useState(false);
  const [error, setError] = useState("");
  const [selectedOption, setSelectedOption] = useState("");
  const [cancelOrderIdList, setCancelOrderIdList] = useState([]);
  const [walletBalances, setWalletBalances] = useState([]);
  const [completedCount, setCompletedCount] = useState(0);

  // setCancelOrderIdList(["0x316BE293C8f2380769e7b7e7382679FE5a3b6600", "0x388C818CA8B9251b393131C08a736A67ccB19297"])

  const [referralCodeCheckStatus, setReferralCodeCheckStatus] = useState("ok");
  const debouncedReferralCode = useDebounce("WalletAddr", 300);
  // let balances;
  // let walletBalances = []

  // const { data: walletBalances } = useSWR([`portfolio:covalentHq:${active}`],
  //   {
  //     fetcher: (key, address) => {
  //       return getERC20TokenBalances(cancelOrderIdList, chainId)
  //         .then((data) => data.data);
  //     },
  //     refreshInterval: 100000000
  //   }
  // );
  // console.log(walletBalances)
  

  // useEffect(() => {
  //   let cancelled = false;
  //   const checkCodeTakenStatus = async () => {
  //     if (error) {
  //       setReferralCodeCheckStatus("ok");
  //       return;
  //     }
  //     const { data: balances } = await getERC20TokenBalances("0xd2458a49cdb7ae8937a153709dce7b17b030a28a", chainId);
  //     console.log(balances)
  //     walletBalances = balances;
  //     // ignore the result if the referral code to check has changed
  //     if (cancelled) {
  //       return;
  //     }
  //     // if (takenStatus === "none") {
  //     //   setReferralCodeCheckStatus("ok");
  //     // } else {
  //     //   setReferralCodeCheckStatus("taken");
  //     // }
  //   };
  //   setReferralCodeCheckStatus("checking");
  //   checkCodeTakenStatus();
  //   return () => {
  //     cancelled = true;
  //   };
  // }, [account, debouncedReferralCode, error, chainId]);

  function exportToCsv(jsonArray) {
    if (!jsonArray || !jsonArray.length){
      return
    }
    let result = jsonArray.map(d => {
      const currentDate = new Date();
      const year = currentDate.getFullYear();
      const month = String(currentDate.getMonth() + 1).padStart(2, '0');
      const day = String(currentDate.getDate()).padStart(2, '0');
      const dateString = `${day}/${month}/${year}`;

      d.report_date = dateString;
      delete d.is_spam;
      delete d.nft_data;
      delete d.logo_url;
      if (d.balance){
        console.log(d.balance)
        console.log(bigNumberify(10))
        console.log(bigNumberify(d.balance))
        d.normalised_balance = bigNumberify(d.balance).div(bigNumberify(10).pow(d.contract_decimals));
      }
      return d
    })
    console.log(result)
    // Extract headers/column names
    console.log(result)
    const headers = Object.keys(result[0]);
    console.log(headers)

    // Convert data to CSV string
    const csvData = [
      headers.join(','), // Header row
      ...result.map(obj => headers.map(header => `"`+obj[header]+`"`).join(',')) // Data rows
    ].join('\n');
    console.log(csvData)

    // Create data URL for CSV
    const csvBlob = new Blob([csvData], { type: 'text/csv' });
    const csvURL = URL.createObjectURL(csvBlob);

    // Create downloadable link
    const downloadLink = document.createElement('a');
    downloadLink.href = csvURL;
    downloadLink.download = 'data.csv'; // Set desired file name

    // Trigger download
    downloadLink.click();
  }


  function renderPortfolios() {
    if (loading) return <Loader />;
    let decoded;
    try {
      decoded = decode(getUserJWT);
      // console.log(decoded)
      if (decoded.exp * 1000 < Date.now()) {
        decoded = null;
        setUserJWT(null);
        throw new Error("EXPIRED_TOKEN");
      }
    } catch (err) {}
    // console.log(decoded)
    if (!account || !decoded?.address) {
      return (
        <LoginCard
          connectWallet={connectWallet}
          active={active}
          getUserJWT={getUserJWT}
          setUserJWT={setUserJWT}
        />
      );
    }
    if (!positions){
      return (
        <PayWall/>
      )
    }

    async function handleSubmit(event) {
      event.preventDefault();
      let addresses = addrs.split(',').map((address) => address.trim());
      addresses = addresses.filter(addr => isValidEthereumAddress(addr));
      console.log(addresses);

      setCancelOrderIdList((prevState) => {
        let filtered = addresses.filter(addr => !prevState.includes(addr));
        return [...prevState, ...filtered];
      });

      let filtered = addresses.filter(addr => !cancelOrderIdList.includes(addr));
      let updatedList = [...cancelOrderIdList, ...filtered];
      console.log(updatedList)

      const runStages = (stage, total) => {
        if (stage > total) {
          setCompletedCount(0);
          return;
        }
        setTimeout(() => {
          setCompletedCount(stage+1);
          runStages(stage + 1, total);
        }, 250);
      }

      runStages(0, updatedList.length);

      setIsProcessing(true);
      const { data: balances } = await getERC20TokenBalances(updatedList, completedCount, setCompletedCount)
      setWalletBalances(balances);
      setIsProcessing(false);

      // const { status: takenStatus, info: takenInfo } = await getReferralCodeTakenStatus(account, referralCode, chainId);
      // if (["all", "current", "other"].includes(takenStatus)) {
      // }

      // if (takenStatus === "none" || takenStatus === "other") {
      //   const ownerOnOtherNetwork = takenInfo[chainId === ARBITRUM ? "ownerAvax" : "ownerArbitrum"];
      //   try {
      //     const tx = await handleCreateReferralCode(referralCode);
      //     if (callAfterSuccess) {
      //       callAfterSuccess();
      //     }
      //     const receipt = await tx.wait();
      //     if (receipt.status === 1) {
      //       recentlyAddedCodes.push(getSampleReferrarStat(referralCode, ownerOnOtherNetwork, account));

      //       helperToast.success(t`Referral code created!`);
      //       setRecentlyAddedCodes(recentlyAddedCodes);
      //       setReferralCode("");
      //     }
      //   } catch (err) {
      //     // eslint-disable-next-line no-console
      //     console.error(err);
      //   } finally {
      //     setIsProcessing(false);
      //   }
      // }
    }
    function getPrimaryText() {
      // if (buttonError) {
      //   return buttonError;
      // }
      console.log(completedCount)
      if (isProcessing) {
        return t`Processing ${completedCount}/${cancelOrderIdList.length} Wallets...`;
      }

      return t`Add Addresses`;
    }

    function isPrimaryEnabled() {
      // if (buttonError) {
      //   return false;
      // }
      if (error || isProcessing) {
        return false;
      }
      return true;
    }

    return (
      // <Dashboard getUserJWT={getUserJWT} setUserJWT={setUserJWT} setPositions={setPositions} positions={positions} />
      <div>
        <form onSubmit={handleSubmit}>
          <input
            type="text"
            // ref={inputRef}
            // value={referralCode}
            // disabled={isProcessing}
            className={cx("text-input", { "mb-sm": !error })}
            placeholder={t`Enter Addresses`}
            onChange={({ target }) => {
              const { value } = target;
              setAddrs(value);
              // setError(getCodeError(value));
            }}
          />
          {error && <p className="error">{error}</p>}
          <button className="App-cta Exchange-swap-button" type="submit" disabled={!isPrimaryEnabled()}>
            {getPrimaryText()}
          </button>
        </form>

        <div className="">
          <button className="App-cta Exchange-swap-button" style={{ marginTop: "2.325rem", marginBottom: "2.325rem" }} onClick={() => { exportToCsv(walletBalances)}}>
            Download CSV
          </button>
          {
            cancelOrderIdList.map(walletAddr => {
              return (
                <button className="App-button-option App-card-option" onClick={() => {
                  setCancelOrderIdList((prevState) => {
                    if (prevState.includes(walletAddr)) {
                      return prevState.filter((i) => i !== walletAddr);
                    } else {
                      return prevState.concat(walletAddr);
                    }
                  });
                }}>
                  <Trans>{walletAddr}</Trans>
                </button>
                // <Checkbox
                //   isChecked={cancelOrderIdList?.includes(walletAddr)}
                //   setIsChecked={() => {
                //     setCancelOrderIdList((prevState) => {
                //       if (prevState.includes(walletAddr)) {
                //         return prevState.filter((i) => i !== walletAddr);
                //       } else {
                //         return prevState.concat(walletAddr);
                //       }
                //     });
                //   }}>
                //   <Trans>{walletAddr}</Trans>
                // </Checkbox>
              )
            })
          }
        </div>

      <div className="token-table-wrapper App-card">
        <div className="App-card-title">
          <Trans>Wallet Balances</Trans> <img src={currentIcons.network} width="16" alt="Network Icon" />
        </div>
        <div className="App-card-divider"></div>
        <table className="token-table">
          <thead>
            <tr>
              <th>
                <Trans>WALLET</Trans>
              </th>
              <th>
                <Trans>TOKEN</Trans>
              </th>
              {/* <th>
                <Trans>NAME</Trans>
              </th> */}
              <th>
                <Trans>BALANCE</Trans>
              </th>
              <th>
                <Trans>VALUE</Trans>
              </th>
            </tr>
          </thead>
          <tbody>
            {walletBalances?.map((bal) => {
              // const tokenInfo = infoTokens[token.address];
              // let utilization = bigNumberify(0);
              // if (tokenInfo && tokenInfo.reservedAmount && tokenInfo.poolAmount && tokenInfo.poolAmount.gt(0)) {
              //   utilization = tokenInfo.reservedAmount.mul(BASIS_POINTS_DIVISOR).div(tokenInfo.poolAmount);
              // }
              // let maxUsdgAmount = DEFAULT_MAX_USDG_AMOUNT;
              // if (tokenInfo.maxUsdgAmount && tokenInfo.maxUsdgAmount.gt(0)) {
              //   maxUsdgAmount = tokenInfo.maxUsdgAmount;
              // }
              // const images = {
              //   "eth-cbeth": "coinbase-wrapped-staked-eth.png",
              //   // "eth-steth": "lido.png",
              //   "eth-steth": "steth_logo.webp",
              //   "weth-steth": "steth_logo.webp",
              //   "eth-frxeth": "frxETH_icon.webp",
              //   "eth-reth": "reth.webp",
              //   "eth-aleth": "aleth.webp",
              //   "eth-seth": "sETH.webp",
              //   "lusd-usdc": "lusd.webp",
              // }
              // const tokenImage = importImage(images[token.symbol.toLowerCase()] || "");
              return (
                <tr key={bal.wallet+bal.contract_address+bal.contract_ticker_symbol}>
                  <td>{shortenAddress(bal.wallet, breakpoint === "S" ? 9 : 13)}</td>
                  <td>
                    <div className="token-symbol-wrapper">
                      <div className="App-card-title-info">
                        {/* <div className="App-card-title-info-icon">
                          <img src={tokenImage} alt={token.symbol} width="40" />
                        </div> */}
                        <div className="App-card-title-info-text">
                          <div className="App-card-info-title">{bal.contract_ticker_symbol}</div>
                          <div className="App-card-info-subtitle">{bal.contract_name}</div>
                        </div>
                        <div>
                          {/* <AssetDropdown assetSymbol={token.symbol} assetInfo={token} /> */}
                        </div>
                      </div>
                    </div>
                  </td>
                  {/* <td>{bal.contract_name}</td> */}
                  <td>{formatAmount(bal.balance, bal.contract_decimals, 2, true)}</td>
                  <td>{bal.quote ? "$" + numberWithCommas(limitDecimals(bal.quote, 2)) : "-"}</td>
                  {/* <td>${token.convex?.userStaked ? numberWithCommas(limitDecimals(token.convex?.userStaked, 2)) : 0}</td> */}
                  {/* <td>{token.stop && token.stop.pid ? padDecimals(token.stop.stoploss, 4) : "N/A"}</td> */}
                  {/* <td>
                          <TooltipComponent
                            handle={`$${formatKeyAmount(tokenInfo, "managedUsd", USD_DECIMALS, 0, true)}`}
                            position="right-bottom"
                            className="nowrap"
                            renderContent={() => {
                              return (
                                <>
                                  <StatsTooltipRow
                                    label={t`Pool Amount`}
                                    value={`${formatKeyAmount(tokenInfo, "managedAmount", token.decimals, 0, true)} ${
                                      token.symbol
                                    }`}
                                    showDollar={false}
                                  />
                                  <StatsTooltipRow
                                    label={t`Target Min Amount`}
                                    value={`${formatKeyAmount(tokenInfo, "bufferAmount", token.decimals, 0, true)} ${
                                      token.symbol
                                    }`}
                                    showDollar={false}
                                  />
                                  <StatsTooltipRow
                                    label={t`Max ${tokenInfo.symbol} Capacity`}
                                    value={formatAmount(maxUsdgAmount, 18, 0, true)}
                                    showDollar={true}
                                  />
                                </>
                              );
                            }}
                          />
                        </td> */}
                  {/* <td>{getWeightText(tokenInfo)}</td> */}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      </div>
    );
  }

  return (
    <SEO title={getPageTitle("Positions")}>
      <div className="default-container page-layout Referrals">
        <div className="section-title-block">
          <div className="section-title-icon" />
          <div className="section-title-content">
            <div className="Page-title">
              <Trans>
                Portfolios <img width="24" src={networkIcon} alt="Network Icon" />
              </Trans>
            </div>
            <div className="Page-description">
              <Trans>
                Track a variety of ERC20 balances across multiple wallets.
              </Trans>
            </div>
          </div>
        </div>
        {renderPortfolios()}
      </div>
      <Footer />
    </SEO>
  );
}

export default Portfolios;
